// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu_menu__g9e3b {\n  position: relative;\n  z-index: 10;\n  display: flex;\n  justify-content: center;\n  align-items: stretch;\n  gap: 12px;\n}\n\n.Menu_noneHeader__nTv4N {\n  max-width: max-content;\n  position: fixed;\n  top: 12px;\n  right: 100px;\n  z-index: 10000;\n}", "",{"version":3,"sources":["webpack://./src/components/menu/Menu/Menu.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,oBAAA;EACA,SAAA;AACF;;AAEA;EACE,sBAAA;EACA,eAAA;EACA,SAAA;EACA,YAAA;EACA,cAAA;AACF","sourcesContent":[".menu {\n  position: relative;\n  z-index: 10;\n  display: flex;\n  justify-content: center;\n  align-items: stretch;\n  gap: 12px;\n}\n\n.noneHeader {\n  max-width: max-content;\n  position: fixed;\n  top: 12px;\n  right: 100px;\n  z-index: 10000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "Menu_menu__g9e3b",
	"noneHeader": "Menu_noneHeader__nTv4N"
};
export default ___CSS_LOADER_EXPORT___;
