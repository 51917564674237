// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ItemLayer_item__XQrx5 {\n  -webkit-user-select: none;\n          user-select: none;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 2px 2px;\n  background-color: #fff;\n  border-bottom: 1px solid #f0f0f0;\n}\n.ItemLayer_item__XQrx5:hover {\n  cursor: pointer;\n  background-color: rgb(252, 252, 252);\n}\n.ItemLayer_item__XQrx5:active {\n  background-color: rgb(255, 255, 255);\n}\n.ItemLayer_item__XQrx5 .ItemLayer_text__8ulUT {\n  display: flex;\n  align-items: center;\n}\n.ItemLayer_item__XQrx5 .ItemLayer_icon__GVmcO {\n  display: inline-block;\n  width: 30px;\n  margin-right: 8px;\n}\n.ItemLayer_item__XQrx5 .ItemLayer_icon__GVmcO img {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/map/ItemLayer/ItemLayer.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;UAAA,iBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;EACA,gCAAA;AACJ;AAAI;EACI,eAAA;EACA,oCAAA;AAER;AAAI;EACI,oCAAA;AAER;AACI;EACI,aAAA;EACA,mBAAA;AACR;AAEI;EACI,qBAAA;EACA,WAAA;EACA,iBAAA;AAAR;AACQ;EACI,WAAA;AACZ","sourcesContent":[".item {\n    user-select: none;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 2px 2px;\n    background-color: #fff;\n    border-bottom: 1px solid #f0f0f0;\n    &:hover {\n        cursor: pointer;\n        background-color: rgb(252, 252, 252);\n    }\n    &:active {\n        background-color: rgb(255, 255, 255);\n    }\n\n    .text {\n        display: flex;\n        align-items: center;\n    }\n\n    .icon {\n        display: inline-block;\n        width: 30px;\n        margin-right: 8px;\n        img {\n            width: 100%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "ItemLayer_item__XQrx5",
	"text": "ItemLayer_text__8ulUT",
	"icon": "ItemLayer_icon__GVmcO"
};
export default ___CSS_LOADER_EXPORT___;
