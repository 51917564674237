// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ItemNote_table__qwrUJ {\n  margin-bottom: 1px;\n}\n\n.ItemNote_item__xx0PK {\n  display: flex;\n  align-items: center;\n  flex: 1 1;\n  margin-bottom: 1px;\n}\n\n.ItemNote_color__kmc8v {\n  width: 35px;\n  height: 20px;\n  border-radius: 4px;\n  margin-right: 5px;\n}\n\n.ItemNote_icon__lvaT\\+ {\n  display: flex;\n  align-items: center;\n}\n.ItemNote_icon__lvaT\\+ img {\n  width: 40px;\n}\n.ItemNote_icon__lvaT\\+ p {\n  margin-left: 4px;\n  flex: 1 1;\n}", "",{"version":3,"sources":["webpack://./src/components/map/NoteTable/components/ItemNote/ItemNote.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;AAAI;EACI,WAAA;AAER;AAAI;EACI,gBAAA;EACA,SAAA;AAER","sourcesContent":[".table {\n    margin-bottom: 1px;\n}\n\n.item {\n    display: flex;\n    align-items: center;\n    flex: 1;\n    margin-bottom: 1px;\n}\n\n.color {\n    width: 35px;\n    height: 20px;\n    border-radius: 4px;\n    margin-right: 5px;\n}\n\n.icon {\n    display: flex;\n    align-items: center;\n    img {\n        width: 40px;\n    }\n    p {\n        margin-left: 4px;\n        flex: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "ItemNote_table__qwrUJ",
	"item": "ItemNote_item__xx0PK",
	"color": "ItemNote_color__kmc8v",
	"icon": "ItemNote_icon__lvaT+"
};
export default ___CSS_LOADER_EXPORT___;
