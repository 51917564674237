// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_container__66bsz {\n  display: flex;\n  align-items: center;\n  padding: 0px 24px;\n  background: linear-gradient(88deg, #08a1f4 -8.01%, #283c8f 96.89%);\n  gap: 24px;\n  position: relative;\n  z-index: 10000;\n  height: 60px;\n}\n.Header_container__66bsz h1 {\n  font-size: 20px;\n  color: #fff;\n}\n\n.Header_logo__g3HNd {\n  width: 40px;\n}\n\n.Header_groupText__V0jzA {\n  position: relative;\n  flex: 1 1;\n  overflow: hidden;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-left: 100px;\n}\n\n.Header_marqueeText__ArqzK {\n  display: inline-block;\n  padding: 10px;\n  font-size: 20px;\n  font-weight: 600;\n  color: yellow;\n}", "",{"version":3,"sources":["webpack://./src/components/layouts/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,kEAAA;EACA,SAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;EACA,WAAA;AACJ;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,qBAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;AAAF","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  padding: 0px 24px;\n  background: linear-gradient(88deg, #08a1f4 -8.01%, #283c8f 96.89%);\n  gap: 24px;\n  position: relative;\n  z-index: 10000;\n  height: 60px;\n\n  h1 {\n    font-size: 20px;\n    color: #fff;\n  }\n}\n\n.logo {\n  width: 40px;\n}\n\n.groupText {\n  position: relative;\n  flex: 1;\n  overflow: hidden;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-left: 100px;\n}\n\n.marqueeText {\n  display: inline-block;\n  padding: 10px;\n  font-size: 20px;\n  font-weight: 600;\n  color: yellow;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Header_container__66bsz",
	"logo": "Header_logo__g3HNd",
	"groupText": "Header_groupText__V0jzA",
	"marqueeText": "Header_marqueeText__ArqzK"
};
export default ___CSS_LOADER_EXPORT___;
